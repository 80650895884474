import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ContentLayout from '../components/ContentLayout/ContentLayout';
import Section from '../components/Section/Section';
import TitleCTA from '../components/TitleCTA/TitleCTA';

export default () => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        notFound {
          globalOptionsNotFound {
            notFoundDescription
            notFoundTitle
            homeCopy
          }
        }
      }
    }
  `);
  const { notFoundDescription, notFoundTitle, homeCopy } = wp?.notFound?.globalOptionsNotFound;
  return (
    <ContentLayout id="NotFound" className="NotFound">
      <Section id="NotFoundSection" data={{ backgroundColor: 'gray', padding: true }}>
        <TitleCTA
          data={{
            title: notFoundTitle,
            description: notFoundDescription,
            cta: { text: homeCopy || 'Home', link: '/' },
          }}
        />
      </Section>
    </ContentLayout>
  );
};
